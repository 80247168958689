import React, { useState } from 'react'
import logo from './assets/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

function App() {
	const [section,setSection] = useState(1)
	const [loading,setLoading] = useState(false)

	const [fullName,setFullName] = useState('')
	const [email,setEmail] = useState('')
	const [gender,setGender] = useState('')
	const [phoneNumber,setPhoneNumber] = useState('')
	const [homeAddress,setHomeAddress] = useState('')
	const [location,setLocation] = useState('')
	const [education,setEducation] = useState('')
	const [employmentStatus,setEmploymentStatus] = useState('')
	const [joiningReason,setJoiningReason] = useState('')
	const [programDuration,setProgramDuration] = useState('')
	const [verifType,setVerifType] = useState('')

	const [nin,setNin ] = useState('')
	const [bvn,setBvn ] = useState('')
	const [dlNumber,setDlNumber ] = useState('')
	const [dob,setDob ] = useState('')
	const [vcNumber,setVcNumber ] = useState('')
	const [vcState,setVcState ] = useState('')
	const [lastName,setLastname ] = useState('')


	const [ninResponse,setNinResponse ] = useState(null)
	const [bvnResponse,setBvnResponse ] = useState(null)
	const [dlResponse,setDlResponse ] = useState(null)
	const [vcResponse,setVcResponse ] = useState(null)


	const [verifResponse,setVerifResponse ] = useState(null)

	const toSectionTwo = ()=>{
        let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

		if(!fullName){
            toast.error('FullName can not be blank', {
                theme: "colored"
            });
			return
		}
		if(!gender){
            toast.error('Please input your gender', {
                theme: "colored"
            });
			return
		}
		if(!email){
            toast.error('Please input your email', {
                theme: "colored"
            });
			return
		}
        if (email < 3) {
            toast.error('Email Address cannot be less than 3 characters', {
                theme: "colored"
            });
            return
        }
        if (email_reg.test(email) === false) {
            toast.error('Email Address is invalid', {
                theme: "colored"
            });
            return
        }
		if(!phoneNumber){
            toast.error('Phone Number can not be blank', {
                theme: "colored"
            });
			return
		}
		if(phoneNumber.length !==  11){
            toast.error('Phone number cant be less or more than 11 digits', {
                theme: "colored"
            });
			return
		}
		else{
			setSection(2)
		}
	}
	const toSectionThree = ()=>{
		if(!homeAddress){
            toast.error('Input Home Address', {
                theme: "colored"
            });
			return
		}
		if(!location){
            toast.error('Please Input your location in Lagos', {
                theme: "colored"
            });
			return
		}
		else{
			setSection(3)
		}
	}
	const toSectionFour = ()=>{
		if(!education){
            toast.error('Please Select your Educational Level', {
                theme: "colored"
            });
			return
		}
		if(!employmentStatus){
            toast.error('Please Select your Employment Status', {
                theme: "colored"
            });
			return
		}
		else{
			setSection(4)
		}
	}
	const toSectionFive = ()=>{
		if(!programDuration){
            toast.error('Please Select your preferred program duration', {
                theme: "colored"
            });
			return
		}
		if(!joiningReason){
            toast.error('Input your reason for joining this program', {
                theme: "colored"
            });
			return
		}
		else{
			setSection(5)
		}
	}

	const submitInfo = () =>{
		
		var myHeaders = new Headers();
		myHeaders.append("x-api-key", "vhL3Jrhy.voB1HZzykl3RLmZpomDCyKHfYBbt4fbH");
		var formdata = new FormData();

		const data ={
			FullName: fullName,
			Email: email,
			Gender: gender,
			Phone_Number: phoneNumber,
			Home_Address: homeAddress,
			Location:location,
			Educational_Level: education,
			Employment_Status: employmentStatus,
			Program_Duration: programDuration,
			Reason_for_Joining: joiningReason,
			Verification_Type : verifType,
			NIN:nin,
			BVN:bvn,
			Driver_ID:dlNumber,
			Driver_DOB:dob,
			Voter_ID:vcNumber,
			Voter_Lastname:lastName,
			Voter_State:vcState,
		}

		// if(!verifType){
		// 	toast.error("Please Select Verification Type", {
		// 		theme: "colored",
		// 	});
		// 	console.log("stage 1")
		// 	return
		// }

		let saveForm = ()=>{
			setLoading(true)
			axios.post('https://sheet.best/api/sheets/e44cec05-e630-4166-9d21-295377e33bdb', data)
			.then(response => {
				toast.success("Your Data has been successfully received", {
					theme: "colored"
				});
				setLoading(false)
			}).catch((error) => {
				toast.error(error, {
					theme: "colored"
				});
				setLoading(false)
			})
		}

		if (verifType === "NIN"){
			
			let nin_reg_ex = /^[0-9]{11}$/
			
			if (!nin) {
				toast.error("Please input your NIN", {
					theme: "colored",
				});
				return
			}
			else if (nin_reg_ex.test(nin) === false) {
				toast.error("Invalid National ID Number", {
					theme: "colored",
				});
				return
			}
			else {
				// saveForm()

				formdata.append("number", nin);
				
				let requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				};

				setLoading(true)
	 
				fetch("https://api.myidentitypay.com/api/v1/biometrics/merchant/data/verification/nin_wo_face", requestOptions)
				.then(response =>{
					response.json()
				})
				.then(result => {
					toast.success("Your Data has been successfully received", {
						theme: "colored"
					});
					setLoading(false)
				})
				.catch(error => {
					toast.error(error, {
						theme:"colored"
					});
					setLoading(false)
				});
			}
			return
		}
		if (verifType === "BVN"){

			let bvn_reg_ex = /^[0-9]{11}$/ ;
		 
			if (!bvn) {
				toast.error("BVN is required", {
					theme: "colored",
				});
				return
			}
			else if (bvn_reg_ex.test(bvn) === false) {
				toast.error("Invalid BVN", {
					 theme: "colored",
				});
				
				return
			}
			else {
				// saveForm()
				formdata.append("number", bvn);
				
				let requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				};

				setLoading(true)

				fetch("https://api.myidentitypay.com/api/v1/biometrics/merchant/data/verification/bvn", requestOptions)
				.then(response => {
					response.json()
				})
				.then(result => {
					toast.success("Your Data has been successfully received", {
						theme: "colored"
					});
					setLoading(false)
				})
				.catch(error => {
					toast.error(error, {
						theme:"colored"
					});
					setLoading(false)
				});
			}
			return
		}
		if (verifType === "DL"){
			let DL_reg_ex = /^[a-zA-Z]{3}([ -]{1})?[A-Z0-9]{6,12}$/
				
			if (!dlNumber || !dob) {
				toast.error("All field is required", {
						theme: "colored",
				});
				return
			}
		  
			else if (DL_reg_ex.test(dlNumber) === false) {
				
			   	toast.error("Invalid Driver's License", {
					theme: "colored",
				});
			   return
		   	}
			else {
				// saveForm()
				formdata.append("number", dlNumber);
				formdata.append("dob", dob);
				
				let requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				};

				setLoading(true)

				fetch("https://api.myidentitypay.com/api/v1/biometrics/merchant/data/verification/drivers_license", requestOptions)
				.then(response => response.json())
				.then(result => {
					// saveForm()
					setDlResponse(result)
					toast.success("Your Data has been successfully received", {
						theme: "colored"
					});
					setLoading(false)
				})
				.catch(error => {
					toast.error(error, {
						theme:"colored"
					});
					setLoading(false)
				});
			}
			return
		}
		if (verifType === "VC"){
			let VC_reg_ex = /^[a-zA-Z0-9 ]{9,19}$/

			if (!vcNumber || !lastName || !vcState) {
			   toast.error("All field is required", {
					theme: "colored",
			   });
			   return
			}
			else if (VC_reg_ex.test(vcNumber) === false) {
			   toast.error("Invalid Voter's Identification Number", {
					theme: "colored",
			   });
		   	}
			else {
				// saveForm()
				formdata.append("number", vcNumber);
				formdata.append("last_name", lastName);
				formdata.append("state", vcState);
				
				let requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow'
				};

				setLoading(true)

				fetch("https://api.myidentitypay.com/api/v1/biometrics/merchant/data/verification/voters_card", requestOptions)
				.then(response => response.json())
				.then(result => {
					// saveForm()
					setDlResponse(result)
					toast.success("Your Data has been successfully received", {
						theme: "colored"
					});
					setLoading(false)
				})
				.catch(error => {
					toast.error(error, {
						theme:"colored"
					});
					setLoading(false)
				});
			}
		}
	}


  	return (
	  	<>
			<ToastContainer/>
			<div class="main-app py-5">
				<div className="row p-0 m-0 align-items-center">
					<div className="offset-lg-1 col-md-5 col-lg-4 ">
						<div className=" nice-bg">
							<img src={logo} className="w-25" alt="" />
							<h5 className="my-5">
								The Youth Connect Initiative Program to provide underserved youths
								with employability skills and connect opportunities through sponsored
								and subsidised programs
							</h5>
							<h6 className="mb-3">Criteria to enroll</h6>
							<p> 1. At least a school leaving certificate</p>
							<p> 2. Must be between 18 to 35 years old</p>
							<p> 3. Must have access to an Internet enabled phone</p>
							<p> 4. Reference from the community leader, church or mosque</p>
							<p> 5. Must have a valid means of Identification - NIN, Voter's card, driver's license or bank account details.</p>
						
						</div>
					</div>
					<div className="col-md-7 ">
						<div className="card py-5">
							<div className="card-body">
								<div className="row align-items-center">
									<div className="col-lg-5 px-5">
										<h5>Enrollment Process</h5>
										{section >= 2 ?
											<div className="title-area done mb-2">
												<span>
													<i className="ri-check-line"></i>
												</span>
												<div>
													<p>Bio Data</p>
													<small>Few informations about you: Name, Gender, Email ...</small>
												</div>
											</div>
											:
											<div className="title-area mb-2">
												<span>
													<p>1</p>
												</span>
												<div>
													<p>Bio Data</p>
													<small>Few informations about you: Name, Gender, Email ...</small>
												</div>
											</div>
										}
										{section >= 3  ?
											<div className="title-area done mb-2">
												<span>
													<i className="ri-check-line"></i>
												</span>
												<div>
													<p>Personal Details</p>
													<small>Where do you stay: Location and Address</small>
												</div>
											</div>
											:
											<div className="title-area mb-2">
												<span>
													<p>2</p>
												</span>
												<div>
													<p>Personal Details</p>
													<small>Where do you stay: Location and Address</small>
												</div>
											</div>
										}
										{section >= 4  ?
											<div className="title-area done mb-2">
												<span>
													<i className="ri-check-line"></i>
												</span>
												<div>
													<p>Educational & Employment</p>
													<small>Your level of Education and employment status</small>
												</div>
											</div>
											:
											<div className="title-area mb-2">
												<span>
													<p>3</p>
												</span>
												<div>
													<p>Educational & Employment</p>
													<small>Your level of Education and employment status</small>
												</div>
											</div>
										}
										{section >= 5 ?
											<div className="title-area done mb-2">
												<span>
													<i className="ri-check-line"></i>
												</span>
												<div>
													<p>Program Choices</p>
													<small>Why are you joining us and your prefered duration</small>
												</div>
											</div>
											:
											<div className="title-area mb-2">
												<span>
													<p>4</p>
												</span>
												<div>
													<p>Program Choices</p>
													<small>Why are you joining us and your prefered duration</small>
												</div>
											</div>
										}
										
										<div className="title-area mb-2">
												<span>
													<p>5</p>
												</span>
											<div>
												<p>Verification</p>
												<small>Document upload</small>
											</div>
										</div>
									</div>
									<div className="col-lg-7 px-3 pe-lg-5">
										<form action="" onSubmit={e=>e.preventDefault()} >
											{section === 1 &&
												<>
													<fieldset className="form-control">
														<legend>Full Name (First-Name first)</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-user-line"></i>
															<input type="text" className="w-100" 
																value={fullName} 
																// name='fullName'
																// onChange={handleChange}
																onChange={(value)=>setFullName(value.target.value)}
																placeholder="John Doe" 
															/>
														</span>
													</fieldset>
													<fieldset className="form-control">
														<legend>Gender</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-profile-line"></i>
															<select
																className=" w-100" 
																// name='gender'
																// onChange={handleChange}
																value={gender}
																onChange={(value)=>setGender(value.target.value)}
															>
																<option value="">select your Gender</option>
																<option value="Male">Male</option>
																<option value="Female" >Female</option>
															</select>
														</span>
													</fieldset>
													<fieldset className="form-control">
														<legend>Enter your email</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-mail-line"></i>
															<input type="text" className="w-100"
																value={email}
																// name='email'
																// onChange={handleChange}
																onChange={(value)=>setEmail(value.target.value)}
																placeholder="johnDoe@gmail.com" 
															/>
														</span>
													</fieldset>
													<fieldset className="form-control">
														<legend>Phone Number</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-profile-line"></i>
															<input type="number" className="w-100"
																value={phoneNumber} 
																// name='phoneNumber'
																// onChange={handleChange}
																onChange={(value)=>setPhoneNumber(value.target.value)}
																placeholder="07012000000" 
															/>
														</span>
													</fieldset>
													
													<button onClick={toSectionTwo} className="btn btn-green py-3 w-100 mt-4">Continue</button>
												</>
											}
											{section === 2 &&
												<>
													<fieldset className="form-control">
														<legend>Home Address</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-map-pin-line"></i>
															<input type="text" className="w-100"
																value={homeAddress} 
																// name='homeAddress'
																// onChange={handleChange}
																onChange={(value)=>setHomeAddress(value.target.value)}
																placeholder="No 20, surulere street, Lagos state" />
														</span>
													</fieldset>
													<fieldset className="form-control">
														<legend>Location In Lagos</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-map-pin-line"></i>
															<input type="text" className="w-100" 
																value={location} 
																// name='location'
																// onChange={handleChange}
																onChange={(value)=>setLocation(value.target.value)}
																placeholder="surulere" 
															/>
														</span>
													</fieldset>
													<div className='d-flex' style={{marginTop:"100px"}}>
														<button onClick={()=>setSection(1)} className="btn btn-green-outline me-3 py-3 w-100 mt-5">Back</button>
														
														<button onClick={toSectionThree} className="btn btn-green py-3 w-100 mt-5">Continue</button>
													</div>
												</>
											}
											{section === 3 &&
												<>
													<fieldset className="form-control">
														<legend>Level of Education</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-user-line"></i>
															<select className='w-100' 
																// name='education'
																// onChange={handleChange}
																value={education}
																onChange={(value)=>setEducation(value.target.value)}
															>
																<option value="">Select Educational Level</option>
																<option value="O-Level">O Level</option>
																<option value="OND">OND</option>
																<option value="HND">HND</option>
																<option value="BSC">BSC</option>
															</select>
														</span>
													</fieldset>
													<fieldset className="form-control">
														<legend>Employment Status</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-profile-line"></i>
															<select className='w-100' 
																// name='employmentStatus'
																// onChange={handleChange}
																value={employmentStatus}
																onChange={(value)=>setEmploymentStatus(value.target.value)}
															>
																<option value="">Select Employment Status</option>
																<option value="Unmemployed">Unemployed</option>
																<option  value="Employed Part Time">Employed Part Time</option>
																<option value="Employed full Time">Employed Full Time</option>
																<option value="Self Employed">Self Employed (Business owner)</option>
															</select>
														</span>
													</fieldset>
													
													<div className='d-flex' style={{marginTop:"100px"}}>
														<button onClick={()=>setSection(2)} className="btn btn-green-outline me-3 py-3 w-100 mt-5">Back</button>
														
														<button onClick={toSectionFour} className="btn btn-green py-3 w-100 mt-5">Continue</button>
													</div>
												</>
											}
											{section === 4 &&
												<>
													<fieldset className="form-control">
														<legend>Preferred Program Duration</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-calendar-line"></i>
															<select className='w-100' 
																// name='programDuration'
																// onChange={handleChange}
																value={programDuration}
																onChange={(value)=>setProgramDuration(value.target.value)}
															>
																<option value="">Select your Program Duration</option>
																<option value="Daily for a Week">Daily for a Week</option>
																<option value="1 day a week for 4  weeks">1 day a week for 4  weeks</option>
																<option value="2 days a week for 2 weeks">2 days a week for 2 weeks</option>
																<option value="others">Others</option>
															</select>
														</span>
													</fieldset>
													<fieldset className="form-control">
														<legend>Reason for joining Program</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-keyboard-line"></i>
															<input type="text" className="w-100" 
																value={joiningReason}
																// name='joiningReason'
																// onChange={handleChange}
																onChange={(value)=>setJoiningReason(value.target.value)}
																placeholder="I joined this program because..." 
															/>
														</span>
													</fieldset>
													
													<div className='d-flex' style={{marginTop:"100px"}}>
														<button onClick={()=>setSection(3)} className="btn btn-green-outline me-3 py-3 w-100 mt-5">Back</button>
														
														<button onClick={toSectionFive} className="btn btn-green py-3 w-100 mt-5">Continue</button>
														{/* <button onClick={submitInfo} className="btn btn-green py-3 w-100 mt-5">Submit Info</button> */}
													</div>
												</>
											}
											{section === 5 &&
												<>
													<fieldset className="form-control">
														<legend>Select Verification Type</legend>
														<span className="d-flex align-items-center pb-2">
															<i className="ri-profile-line"></i>
															<select className='w-100'
																value={verifType}
																onChange={(value)=>setVerifType(value.target.value)}
															>
																<option value="">Select Type</option>
																<option value="NIN">NIN</option>
																<option value="BVN">BVN</option>
																<option value="VC">Voters Card</option>
																<option value="DL">Driver's License</option>
																{/* <option value="bankAccount">Bank Account Verification</option> */}
															</select>
														</span>
													</fieldset>

													{verifType === "NIN" &&
														<fieldset className="form-control">
															<legend>Enter NIN</legend>
															
															<span className="d-flex align-items-center pb-2">
																<i className="ri-keyboard-line"></i>
																<input type="text" value={nin} onChange={(value)=>setNin(value.target.value)}  className="w-100" placeholder="0023789910" />
															</span>
														</fieldset>
													}
													{verifType === "BVN" &&
														<fieldset className="form-control">
															<legend>Enter BVN</legend>
															
															<span className="d-flex align-items-center pb-2">
																<i className="ri-keyboard-line"></i>
																<input type="text" value={bvn} onChange={(value)=>setBvn(value.target.value)}  className="w-100" placeholder="67849035657" />
															</span>
														</fieldset>
													}
													{verifType === "VC" &&
														<div>
															<fieldset className="form-control">
																<legend>Enter Voter's Id Number</legend>
																<input type="text" value={vcNumber} onChange={(value)=>setVcNumber(value.target.value)}  className="w-100" placeholder="90A5C1D8174K804D968" />
															</fieldset>
															<fieldset className="form-control">
																<legend>Enter lastName</legend>
																<input type="text" value={lastName} onChange={(value)=>setLastname(value.target.value)}  className="w-100" placeholder="Peter" />
															</fieldset>
															<fieldset className="form-control">
																<legend>Enter State</legend>
																<input type="text" value={vcState} onChange={(value)=>setVcState(value.target.value)}  className="w-100" placeholder="Lagos" />
															</fieldset>
														</div>
													}
													{verifType === "DL" &&
														<div>
															<fieldset className="form-control">
																	<legend>Enter Driver's Id Number</legend>
																	
																<span className="d-flex align-items-center pb-2">
																	<i className="ri-keyboard-line"></i>
																	<input type="text" autocomplete="none" value={dlNumber} onChange={(value)=>setDlNumber(value.target.value)}  className="w-100" placeholder="ABC002050050" />
																</span>
															</fieldset>
															<fieldset className="form-control">
																<legend>Enter Date of Birth</legend>
																<input type="date" value={dob} onChange={(value)=>setDob(value.target.value)}  className="w-100" placeholder="2002-10-27" />
															</fieldset>
														</div>
													}
													{/* {verifType === "bankAccount" &&
														<bankAccountRequest verifyData={this.verifyData} country={this.state.selected_country} isLoading={this.props.verificationState.isLoading} url={"biometrics/merchant/data/verification/portal/ng/bank_account"}/>
													} */}
													
													<div className='d-flex justify-content-center'>
														<button onClick={()=>setSection(4)} className="btn btn-green-outline me-3 py-3 w-100 mt-5">Back</button>

														{(verifType === "NIN" || verifType === "BVN" || verifType === "VC" || verifType === "DL" || verifType === "bankAccount") &&
															<button onClick={submitInfo} className="btn btn-green py-3 w-100 mt-5">
																{loading ?
																	<div>
																		<Spinner
																			as="span"
																			animation="border"
																			size="sm"
																			role="status"
																			aria-hidden="true"
																		/>
																		<span className="sr-only">Loading...</span>
																	</div>
																	:
																	"Submit Info"
																}
																
															</button>
														}
													</div>
												</>
											}
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
  	);
}


export default App;
